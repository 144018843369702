<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:38
-->
<template>
	<div class="enrollResult">
		<common-title :title="'录取结果通知'" />
		<div class="enrollResult-content">
			<div class="common-table">
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">录取消息</span>
						<el-input type="textarea" v-model="enrollResult.plan" placeholder="请输入"
							class="common-table-val" />
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">替补消息</span>
						<el-input type="textarea" v-model="enrollResult.plan" placeholder="请输入"
							class="common-table-val" />
					</div>
				</div>
			</div>
		    <div class="enrollResult-title">
		    	报名信息
		    </div>
			<div class="enrollResult-btn flex-between">
				<div class="flex-item">
					<el-button>录取人员选择</el-button>
					<el-button>替补人员选择</el-button>
				</div>
			</div>
			<div class="enrollResult-table">
				<el-table :data="tableData" border>
					<el-table-column prop="index" label="序号">
					</el-table-column>
					<el-table-column prop="name" label="姓名">
					</el-table-column>
					<el-table-column prop="time" label="学号">
					</el-table-column>
					<el-table-column prop="type" label="性别">
					</el-table-column>
					<el-table-column prop="status" label="学院班级">
					</el-table-column>
					<el-table-column prop="status" label="录取结果">
					</el-table-column>
					<el-table-column prop="status" label="发送状态">
					</el-table-column>
					<el-table-column prop="status" label="发送时间">
					</el-table-column>
				</el-table>
			</div>
			<div class="enrollResult-handle flex-between">
				<div class="flex-item">
					<el-button type="primary">录取通知发送</el-button>
					<el-button>重置</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				enrollResult: {},
				tableData: []
			};
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
	}
	
	/deep/.common-table-row .el-textarea__inner {
		resize: none;
		height: 300px;
		border: 0;
	}
	
	/deep/.enrollResult-handle .el-button--default {
		color: #606266 !important;
		border: 1px solid #DCDFE6 !important;
	}

	.flex-item {
		display: flex;
		align-items: center;
	}
	
	.flex-between {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.enrollResult {
		.enrollResult-content {
			padding: 0 20px 20px 20px;
			
			.enrollResult-title {
				font-size: 16px;
				font-family: PingFang SC;
				font-weight: bold;
				line-height: 22px;
				color: #1C2233;
				margin-top: 40px;
			}
			
			.enrollResult-btn {
				margin: 10px 0;
				display: flex;
				align-items: center;
			}
			
			.enrollResult-handle {
				margin: 10px 0;
				display: flex;
				align-items: center;
			}
		}
	}
</style>
